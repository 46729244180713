<template>
	<div>
		<v-card>			
			<s-toolbar dense>
				<v-toolbar-title>
					<v-row style="margin-top: -10px">
						<v-col cols="12" style="display: inline-flex;">
							<v-chip
								fab								
								small
								:color="'red'"
								@click="$emit('close')"
							>
								<i style="font-size: 12px" class="fas fa-times"></i>
							</v-chip>
						</v-col>							
					</v-row>
				</v-toolbar-title>
			</s-toolbar>
		</v-card>
		<v-card dense>
				
				<v-card-text>
					<v-row>
						<v-col >
							<b>ID Pallet : </b> {{ PalletSelect.PalletGroupID }}
						</v-col>
						<v-col >   
							<b>Cliente : </b> {{ PalletSelect.CumNombre }}
						</v-col>
						<v-col >   
							<b>Cultivo : </b> {{ PalletSelect.TypeCultiveName }}
						</v-col>
					</v-row>
					<v-row>
						<v-col >
							<b>N° Cajas : </b> {{ PalletSelect.NumberBoxes }}
						</v-col>
						<v-col >   
							<b>Peso (Kg) : </b> {{ PalletSelect.WeightNetBoxes }}
						</v-col>
						<v-col >   
							<b>Ubicacion : </b> {{ PalletSelect.PalletLocation }}
						</v-col>
					</v-row>
					<v-row>
						
						<v-col >
							<b>Motivo Observacion :</b> <s-textarea v-model="PalletSelect.PocObservation"></s-textarea> 
						</v-col>
					</v-row>
					
				</v-card-text>
				<v-card-actions>
					<v-row>
						<v-col>
							<v-btn  
								color="success"
								@click="sendEditObs()">
								Guardar
							</v-btn>
						</v-col>
					</v-row>
				</v-card-actions>
			
		</v-card>

		
	</div>
</template>

<script>
	
	import _sFrzIncomePalletObservedChamber from "@/services/FrozenProduction/FrzIncomePalletObservedChamber.js";
import SToolbar from '../../../../components/Utils/SToolbar.vue';

	export default {
		props: {
			PalletSelect:{
                type: Object,
                required: true,
            }
		},
		components: {SToolbar  },
		data() {
			return {
	
			};
		},

		created() {
		},

		methods: {

			close(){
				this.$emit('close')
			},
			sendEditObs(){
				console.log('editar pallet');
				let val = {
                    PocID : this.PalletSelect.PocID,
					CipID	: this.PalletSelect.CipID,
                    UsrUpdateID : this.$fun.getUserID(),
					PalletGroupIDParent : this.PalletSelect.PalletGroupIDParent,
					PocObservation : this.PalletSelect.PocObservation
                }

                console.log('data',val);
                // return
                this.$fun.alert("Esta de seguro de Editar ?", "question")
                .then(res => {
                    if (res.value) {
                        _sFrzIncomePalletObservedChamber
                        .editPalletObs(val, this.$fun.getUserID())
                        .then(r => {
                            if (r.status == 200) {
                                this.$fun.alert("Pallet editado correctamente", "success");
                                this.close();
                            }
                        });
                    }
                });

			},
            
		},
        created(){
           console.log(this.PalletSelect);
        }
	};
</script>

<style>
</style>