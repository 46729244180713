import Service from "../Service";

const resource = "FrzChamberPalletLosses/";

export default {
    save(obj, requestID){
        return Service.post(resource + "save", obj,{
            params: {requestID: requestID},
        });
    },
    list(fas, requestID) {
        return Service.post(resource + "list", fas, {
          params: { requestID: requestID },
        });
    },
    listDetail(fas, requestID) {
        return Service.post(resource + "listDetail", fas, {
          params: { requestID: requestID },
        });
    },
    // pagination(dtr, requestID){
    //     return Service.post(resource + "pagination", dtr,{
    //         params:{requestID: requestID},
    //     });
    // },
    
    

}