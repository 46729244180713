import Service from "../Service";
import Vue from "vue";
const resource = "subgroupobservation/";

export default {
    save(prd, requestID) {
        return Service.post(resource + "save", prd, {
            params: { requestID: requestID },
        });
    },

    pagination(parameters, requestID) {
        return Service.post(resource + "pagination", parameters, {
            params: { requestID: requestID },
        });
    },

    list(parameters, requestID) {
        return Service.post(resource + "list", parameters, {
            params: { requestID: requestID },
        });
    },

    // list(obj, requestID) {
    //     return Service.post(resource + "list", obj, {
    //         params: { requestID: requestID },
    //     });
    // },

    // GetUrllistVariaty() {
    //     return Vue.prototype.$http.defaults.baseURL + "/fruitstate/list";
    // },

};