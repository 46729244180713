<template>
    <div>
        <v-card>
            <v-toolbar dense>
                <v-toolbar-title>
                    <v-row style="margin-top: 10px">
                        <v-col cols="12" style="display: inline-flex;">
                            <v-chip
                                style="margin: 0px"
                                small
                                :color="'info'"
                            >
                                {{ "Ingreso QR" }} 
                            </v-chip>
                        </v-col>
                    </v-row>
                </v-toolbar-title>
            </v-toolbar>
        </v-card>
        <v-card dense>
            <v-row justify="center" style="margin:auto">
                <v-col
                    align="center"
                    cols="12"
                    lg="12"
                    md="12"
                >   
                    <s-scanner-qr
                        :config="configScann"
                        return-object 
                        @onValue="onValue($event)"
                        :readonly="false"
                        :autofocus="false"
                        :clearInput="clearInput"
                        >
                    </s-scanner-qr>
                </v-col>
            </v-row>
             <v-row justify="center" style="margin:auto">
                <v-col  cols="4" lg="4" md="4">
                    <v-btn small block left color="primary" dark @click="saveObservations()" hide-details>
                        <v-icon left small>fas fa-save</v-icon> Ingresar Observacion
                    </v-btn>
                </v-col>
            </v-row>
            <v-row justify="center" style="margin:auto">
                
                <v-col lg="2">
                    <s-select-definition
                        label="Grupo Observacion"
                        v-model="itemHead.TypeGroup"
                        clearable
                        :def="1468"
                        @change="ChangeGrup()"
                        >

                    </s-select-definition>
                </v-col>
                <v-col lg="2" v-if="SubGroups.length > 0">
                    <s-select
                        :items="SubGroups"
                        label="Sub Grupo Observacion"
                        v-model="itemHead.TypeSubGroup"
                        item-value="FsgID"
                        item-text="FsgName"         
                        @input="ChangeGrup()"
                        >

                    </s-select>
                </v-col>
                <v-col lg="2">
                    <s-select
                        label="Tipo de Observacion"
                        v-model="itemHead.TypeObservation"
                        :items="ItemsTypeObservation"
                        item-value="FopID"
                        item-text="TypeObservation"  
                        >

                    </s-select>
                </v-col>
            </v-row>
            <v-row justify="center" style="margin:auto">
                <v-col  cols="4" lg="4" md="4">
                    <s-textarea
                        label = "Descripcion"
                        v-model="itemHead.PocObservation"
                        clearable   
                    >
                    </s-textarea>
                </v-col>
            </v-row>
            <v-row justify="center">
                <v-col>
                    <v-data-table
                            dense
                            :items="itemsPalletDetail"
                            :headers="HeaderPalletDetail"
                            :items-per-page="-1"                        
                            disable-sort    
                            show-select      
                            item-key="CidID"                        
                            v-model="itemPalletContent"               
                            hide-default-footer>
                            <template v-slot:item.TotalBox="{ item }">
                                <s-text v-model="item.TotalBox" type="number" :min="0" @input="UpdateWeight(item)"  >

                                </s-text>
                            </template>
                            <template v-slot:item.IsObserved="{ item }">
                                <tr v-if="item.IsObserved == 1">
                                <v-chip  x-small class="ma-2" color="red" text-color="white" @click="CargarObservacion(item)">
                                        SI
                                </v-chip>
                                </tr>
                                <tr v-if="item.IsObserved != 1">
                                <v-chip  x-small class="ma-2" color="green" style="font-size: 10px;font-weight: bold;" text-color="white">
                                        NO
                                </v-chip>
                                </tr>
                            </template>
                            
                    </v-data-table>
                </v-col>
            </v-row>
            
            <v-row>
				<v-col lg="12" class="pb-1">
					<s-crud
						:filter="filter"
						:config="config"	
						searchInput
						title="Listado de pallets Observados"
						no-full
						height="auto"
						dense
						ref="gridIncomeArea"
					>
						
						<template v-slot:filter>
							<v-container>
								<v-row justify="center" class="s-col-form">
									
									<v-col justify="center" lg="3" cols="12"
										><s-date
											label="Fecha Inicio"
											v-model="filter.DateBegin"
										></s-date>
									</v-col>
									<v-col lg="3" cols="12"
										><s-date
											label="Fecha Fin"
											v-model="filter.DateEnd"
										></s-date>
									</v-col>
								</v-row>
								
								<v-row class="s-col-form">
									
								</v-row>
							</v-container>
						</template>

						
						
                        <template v-slot:PalletGroupID="{ row }">
							<v-chip small color="warning">
                                {{ row.PalletGroupID }}
                            </v-chip>
						</template>

                        <template v-slot:Edit="{ row }">
							<v-btn
								x-small
								fab
								color="info"
								@click="EditPallet(row)">
								<v-icon
                                    style="font-size: 16px !important">
                                    mdi-lead-pencil
                                </v-icon>
							</v-btn>
						</template>

						<template v-slot:details="{ row }">
							<v-btn
								x-small
								fab
								style="margin: 6px 6px 6px 6px; background-color: #dd3b62; color: white;"
								@click="deleteParihuela(row)">
								<v-icon
                                    style="font-size: 16px !important">
                                    fas fa-times
                                </v-icon>
							</v-btn>
						</template>
						
					</s-crud>
				</v-col>
			</v-row>
                  
        </v-card>
    
    <v-dialog
        v-if="modalEditPalletObs"
        v-model ="modalEditPalletObs"
        width="800"
        height="auto"
    >
        <pallet-edit :PalletSelect ="PalletSelect" @close="close()"></pallet-edit>
    </v-dialog>
    </div>
</template>
<script>
    import sScannerQr from '../../../../components/Utils/sScannerQr.vue';

    import _sFrzIncomePalletObservedChamber from "@/services/FrozenProduction/FrzIncomePalletObservedChamber.js";
    import _sFrzChamberPalletLosseService from "@/services/FrozenProduction/FrzChamberPalletLosses.js";

    import PalletEdit from "./FrzIncomePalletObservedEdit.vue"
   
    import _sFrzCameraIncomePallet from "@/services/FrozenProduction/FrzCameraIncomePallet.js";
    import _sSubGroupService from "@/services/FrozenProduction/SubGroupService.js";
    import _sFrzTypeObservation from "@/services/FrozenProduction/FrzTypeObservation.js";
    export default{
        components: { sScannerQr,PalletEdit },
        data:() => ({
            openModalIncomeTunnel :false,
            clearInput:false,
            configScann: {}, 

            filter: {
                DateBegin: null,
                DateEnd: null,                    
            },
            
            config: {
				model: {
					PocID: "ID",
                    DateBegin: "date",
                    DateEnd: "date",
                    SecStatus: "SecStatus",
                    details:"",
                    PalletGroupID:"",
                    Edit:""
				},
				service: _sFrzIncomePalletObservedChamber,
				headers: [		
                    { text: "Editar", value: "Edit", width: 30 },	
                    { text: "Fecha", value: "DateIncome", width: 30 },
					{ text: "Hora", value: "HourIncome", width: 30 },	
                    { text: "Ubicacion", value: "PalletLocation", width: 30 },				
					{ text: "ID Pallet", value: "PalletGroupID", width: 20 },
                    { text: "ID Proveedor", value: "RcfIDs", width: 20 },
					{ text: "Cliente", value: "CumNombre", width: 60 },
                    { text: "Marca", value: "CbdBrans", width: 60 },
                    { text: "Variedad", value: "VrtDescription", width: 60 },
                    { text: "Tipo de Corte", value: "TypeCutName", width: 60 },
                    { text: "Presentacion", value: "TypePackingName", width: 60 },
					{ text: "N° de Cajas", value: "NumberBoxes", width: 20 },
					{ text: "Peso Neto", value: "WeightNetBoxes", width: 30 },		
                    { text: "Cultivo", value: "TypeCultiveName", width: 30 },		
                    { text: "condicion", value: "TypeObservationName", width: 30 },
                    { text: "Detalle Motivo", value: "PocObservation", width: 30 },
                    { text: "T. Congelamiento", value: "CecDescription", width: 30 },
                    { text: "Detalle", value: "details", width: 20 },		
				],
			},
            itemHead:{},
            modalEditPalletObs : false,
            dataPallet: {
                Pallets:{
                },
            },
            itemPalletObs: 0,
            itemPalletDetailObs: 0,
            itemPalletObsPacking: 0,
            showMore: false,
            itemPalletContent: [],
            itemsPalletDetail: [],
            HeaderPalletDetail: [
                { text:"Grupo ID", value :"PalletGroupID"},
                { text: "Trazabilidad", value: "TraceabilityID"},
                { text: "Lote", value: "Lote"},
                { text: "Cliente", value: "CumNombre"},
                { text: "Marca", value: "BransName"},
                { text: "Cultivo", value: "TypeCultiveName"},
                { text: "Variedad", value: "VrtDescription"},
                { text: "Tipo de Corte", value: "TypeCutName"},
                { text: "Presentacion", value: "TypePresentationName"},
                { text: "N° Cajas", value: "TotalBox",  width: "100px" },
                { text: "Peso Neto", value: "TotalBagWeight"},
                // { text: "Condicion", value: "IsObservedString"},
                { text: "T. Congelamiento", value: "CecDescription"},
                { text: "Localizacion", value: "PalletLocation" },
                { text: "E. Final", value: "StateFinalString" },
                // { text: "E. Final", value: "StateFinal" },
                { text: "Observado", value: "IsObserved" },
                // { text: "Guardar Rol", value: "saveRoll", align: "center"}

            ],
            dialogObservation: false,
            ObservedString: '',
            SubGroups: [],
            ItemsTypeObservation: [],
           
        }),
        
        
        methods:{
            ChangeGrup()
        {
            // 
            _sSubGroupService.list({TypeGroup: this.itemHead.TypeGroup}, this.$fun.getUserID())
            .then( r => {
                if(r.status == 200)
                {                   
                    this.SubGroups = r.data;                    
                }
            });

            _sFrzTypeObservation.list({TypeGroup: this.itemHead.TypeGroup, TypeSubGroup: this.itemHead.TypeSubGroup}, this.$fun.getUserID())
            .then( r => {
                if(r.status == 200)
                {                   
                    this.ItemsTypeObservation = r.data;
                }
            });
        },
            
            onValue(val)
            {
                // if (val == "Sin datos" || isNaN(val)) {
				// 	this.$fun.alert("El QR Leido no contiene datos", "warning");
				// } else {

                    
                //     let item = {};
                //     item.PalletGroupID = val;
                //     this.PalletGroupID = val;
                    
                //     _sFrzChamberPalletLosseService.list(item, this.$fun.getUserID())
                //     .then( r => {
                //         if(r.status == 200)
                //         {
                //             this.dataPallet = r.data;                            
                //         }
                //     });
                // }

                this.PalletGroupID = val;
                if (val == "Sin datos" || isNaN(val)) {
					this.$fun.alert("El QR Leido no contiene datos", "warning");
				} else {

                    _sFrzCameraIncomePallet.getPalletByGroupID({PalletGroupID: val}, this.$fun.getUserID())
                    .then((r) => {
                        if(r.status == 200)
                        {
                            if(r.data.length == 0){
                                this.$fun.alert("Pallet no existe", "warning");
                                return;
                            }
                            
                            r.data[0].palletDetailCEs.forEach(element => {     
                                element.Lote = r.data[0].Lote;
                                element.NumberBoxesOld = element.TotalBox;  
                                if(element.TotalBox > 0 && element.TotalBagWeight > 0)
                                {
                                    this.Validate = true;
                                }
                                element.WeightNetBoxesOld = parseFloat(element.TotalBagWeight);  
                                element.TotalBox = element.TotalBox;         
                                element.TotalBagWeight = element.TotalBagWeight;  
                                element.CecDescription = r.data[0].CecDescription;   
                                element.BransName = r.data[0].BransName; 
                                element.PalletLocation = r.data[0].PalletLocation;   
                                element.PalletGroupID = r.data[0].PalletGroupID;  
                                element.CumNombre = r.data[0].CumNombre; 
                                element.CumID = r.data[0].CumID;      
                                element.CmaID = r.data[0].CmaID; 
                                element.TypeCut = r.data[0].TypeCut;    
                                element.CbdID = r.data[0].CbdID;    
                                element.SideID = r.data[0].SideID; 
                                element.NumberLanes = r.data[0].NumberLanes;     
                                element.NumberLevels = r.data[0].NumberLevels;   
                                element.PositionsLeve = r.data[0].PositionsLeve;    
                                element.CdtID = r.data[0].CdtID;   
                                element.TypeVariant = r.data[0].TypeVariant;    
                                element.TypeProduction = r.data[0].TypeProduction; 
                                // element.StateFinal = r.data[0].StateFinal; 
                                this.itemsPalletDetail.push(element);                    
                            });  
                            
                        }
                    });
                }  
            },
            saveObservations(){
                
                
                
                this.itemPalletContent.forEach(element => {
                    element.TypeObservation = this.itemHead.TypeObservation;
                    element.PocObservation = this.itemHead.PocObservation;
                    element.TypeGroup = this.itemHead.TypeGroup;
                    element.TypeSubGroup = this.itemHead.TypeSubGroup;                    
                });
                // return
                _sFrzIncomePalletObservedChamber.save(this.itemPalletContent, this.$fun.getUserID())
                    .then( r => {
                        if(r.status == 200)
                        {
                            
                            this.$fun.alert("Pallet ingresado correctamente", "success");
                            this.dataPallet = [];
                            this.itemsPalletDetail = [];
                            this.$refs.gridIncomeArea.refresh();
                        }
                    });
                

            },
            deleteParihuela(item){
                let val = {
                    PocID : item.PocID,
                    CipID : item.CipID,
                    UsrUpdateID : this.$fun.getUserID(),
                    PalletGroupIDParent : item.PalletGroupIDParent,
                    CidID: item.CidID,
                }

                console.log(item);
                // return
                this.$fun.alert("Esta de seguro de eliminar ?", "question")
                .then(res => {
                    if (res.value) {
                        _sFrzIncomePalletObservedChamber
                        .update(val, this.$fun.getUserID())
                        .then(r => {
                            if (r.status == 200) {
                                this.$fun.alert("Pallet eliminado correctamente", "success");
                                this.$refs.gridIncomeArea.refresh();
                            }
                        });
                    }
                });


            },
            EditPallet(item){
                
                this.modalEditPalletObs = true
                this.PalletSelect = item
            },
            close(){
                this.modalEditPalletObs = false
                this.$refs.gridIncomeArea.refresh();
            },
            ShowViewDetails()
			{
				if(this.showMore)
				{
					this.showMore = false;
				}else{
					this.showMore = true;
					// this.GetDollByTable();
				}
				
			},
        },
        created()
        {
            
        }
    }
</script>