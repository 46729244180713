import Service from "../Service";
const resource = "FrzIncomePalletObservedChamber/";

export default {
    pagination(parameters, requestID) {        
        return Service.post(resource + "pagination", parameters, {
            params: { requestID: requestID },
        });
    },

    save(abt, requestID){
        return Service.post(resource + "save", abt,{
            params: { requestID: requestID }
        });
    },
    update(abt, requestID){
        return Service.post(resource + "update", abt,{
            params: { requestID: requestID }
        });
    },
    editPalletObs(abt, requestID){
        return Service.post(resource + "editPalletObs", abt,{
            params: { requestID: requestID }
        });
    },    
   
}


